import { useForm } from 'react-hook-form'

import FormMain from 'src/components/common/form/FormMain'
import Forward from '../../../../assets/images/input/forward.png'
import {
  CONTENT_PADDING,
  CONTENT_PADDING_BOTTOM,
  CONTENT_PADDING_TOP,
  PLANT_CARE,
  SAMPLE_PLANT,
} from 'src/utils/common/constants/constants'

import ChoiceChips from 'src/components/common/form/sub/select/ChoiceChips'
import CustomCalendar from 'src/components/common/form/sub/calendar/CustomCalendar'
import CustomDualCalendar from 'src/components/common/form/sub/calendar/CustomDualCalendar'
import { useEffect, useState } from 'react'
import Title from 'src/components/common/title/Title'
import { Box } from '@mui/material'
import CustomLocation from 'src/components/common/form/sub/loc/Location'
import LocationSelect from 'src/components/common/form/sub/loc/LocationSelect'
import styled from 'styled-components'

import ProfileFile from 'src/components/common/form/sub/file/ProfileFile'
import { useUpdateProfile } from '../hook/ProfileCreateCustomHook'
import { ProfileInfoType } from 'src/utils/common/type/type'

export default function ProfileForm({
  onClick,
  profile,
  onPostSuccess,
}: {
  onPostSuccess: () => void
  profile: ProfileInfoType | null
  onClick: () => void
}) {
  const { register, getValues, setValue, watch, handleSubmit } = useForm()
  const [editLoc, setEditLoc] = useState(false)
  const [locationInfo, setLocationInfo] = useState('내 위치를 선택해 주세요')
  const [toggle, setToggle] = useState(false)
  const { createData, isValidObj, checkTitle, checkContent, confirmed, formAlert, isSuccess, isError } =
    useUpdateProfile()

  useEffect(() => {
    if (isSuccess) {
      onPostSuccess()
    }
  }, [isSuccess])
  useEffect(() => {
    if (isError) {
      onPostSuccess()
    }
  }, [isError])

  useEffect(() => {
    const subscirbe = watch((data, { name }) => {
      if (name === 'title') {
        if (profile?.data.nickname !== data.title) {
          checkTitle(getValues('title'))
        }
      } else if (name === 'content') {
        checkContent(getValues('content'))
      }
    })

    return () => subscirbe.unsubscribe()
  }, [watch])

  useEffect(() => {
    setValue('title', profile?.data.nickname)
    if (profile?.data.introduction) {
      setValue('content', profile?.data.introduction)
    }
    register('position')
    register('location')

    if (profile?.data.location) {
      if (profile.data.address) {
        setValue('location', `${profile.data.address?.sigungu} ${profile.data.address?.eupmyundong}`)
        setLocationInfo(`${profile.data.address?.sigungu} ${profile.data.address?.eupmyundong}`)
      }

      setValue('position', {
        lat: profile.data.location.latitude,
        lng: profile.data.location.longitude,
      })
    } else {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords

        setValue('position', {
          lat: latitude,
          lng: longitude,
        })
      })
    }
    setToggle(prev => !prev)
  }, [])

  const onClickSubmitBtn = (data: any) => {
    console.log(data)
    if (profile?.data.nickname !== data.title && !checkTitle(getValues('title'))) {
      return
    }
    if (!checkContent(getValues('content'))) return

    let isPicUploaded = false
    const form = new FormData()
    const files = getValues('image')
    if (files) {
      form.append(`profileImage`, files, files.name)
      isPicUploaded = true
    }

    createData(
      {
        nickName: data.title,
        indroduction: data.content,
        lat: data.location ? data.position.lat : '',
        lon: data.location ? data.position.lng : '',
        isPicUploaded: isPicUploaded,
      },
      form,
    )
  }

  useEffect(() => {
    const subscirbe = watch((data, { name }) => {})

    return () => subscirbe.unsubscribe()
  }, [watch])

  return (
    <>
      <FormMain styleID="scroll" onSubmit={handleSubmit(onClickSubmitBtn)}>
        <Wrap>
          <Box sx={{ p: CONTENT_PADDING, pt: CONTENT_PADDING_TOP, pb: CONTENT_PADDING_BOTTOM }}>
            <>
              <ProfileFile
                sampleUrl={profile?.data.imageUrl || null}
                getValues={getValues}
                onAddFile={(file: File) => {
                  setValue('image', file)
                  // searchImg(file)
                }}
              />
            </>
            <FormMain.LabelLayout
              infoStyleId="text_red"
              isFirst={false}
              warning={isValidObj && isValidObj.title === false ? true : false}
              warnText={formAlert && formAlert.title ? formAlert.title : ''}
              label="닉네임"
            >
              <FormMain.InputFull
                styleId={
                  isValidObj && isValidObj.title === false
                    ? 'div_plant_02 wdt_full mb_10 border_red'
                    : 'div_plant_02 wdt_full mb_10'
                }
                inputComp={
                  <FormMain.Input type="text" placeholder="닉네임을 입력해주세요" registerFn={register('title', {})} />
                }
              />
            </FormMain.LabelLayout>

            <FormMain.LabelLayout
              warning={isValidObj && isValidObj.location === false ? true : false}
              warnText={formAlert && formAlert.location ? formAlert.location : ''}
              label="내 위치 설정"
            >
              <>
                <CustomLocation
                  onClick={() => {
                    setEditLoc(true)
                  }}
                  title={locationInfo}
                  iconComp={
                    <>
                      <img style={{ width: '9px', height: '17px' }} src={Forward} alt="내 위치 선택" />
                    </>
                  }
                />
              </>
            </FormMain.LabelLayout>
            <FormMain.LabelLayout
              warning={isValidObj && isValidObj.content === false ? true : false}
              warnText={formAlert && formAlert.content ? formAlert.content : ''}
              label="내용"
            >
              <FormMain.TextArea
                warning={isValidObj && isValidObj.content === false ? true : false}
                registerFn={register('content', {
                  required: false,
                })}
                placeholder="내용을 입력해주세요"
              />
            </FormMain.LabelLayout>

            <FormMain.Button
              marginTop="23px"
              width="100%"
              styleID={confirmed === true ? 'btn_submit_01_active' : 'btn_submit_01'}
              type="submit"
            >
              작성 완료
            </FormMain.Button>
          </Box>
        </Wrap>
        {getValues('position') ? (
          <LocationSelect
            defaultVal={{
              lat: getValues('position.lat'),
              lng: getValues('position.lng'),
            }}
            setValue={setValue}
            isVisible={editLoc}
            onClick={() => {
              setEditLoc(false)
              if (getValues('location')) {
                setLocationInfo(getValues('location'))
              }
            }}
            onClose={() => {
              setEditLoc(false)
            }}
            watch={watch}
            name="location"
            register={register}
            registerFn={register('location', {
              required: false,
            })}
            getValues={getValues}
          />
        ) : (
          <></>
        )}
      </FormMain>
    </>
  )
}

const Wrap = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
`
