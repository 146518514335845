import React, { useEffect, useState } from 'react'
import { FollowType } from 'src/utils/common/type/type'
import { useFollow } from '../../profile/hook/ProfileCreateCustomHook'
import sampleImg from '../../../../assets/images/post/sampleProfile.png'

import {
  ProfileContainer,
  ProfileDivComp,
  ProfileImg,
  ProfileIntroComp,
  ProfileWrap,
} from 'src/components/common/content/ProfileHeader'
import styled from 'styled-components'
import { Skeleton } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useProfilePageStatus } from 'src/utils/store/profile/useProfilePageStatus'
import { toast } from 'react-toastify'

const FollowItem = function FollowItem({
  defaultVal,
  item,
  isFollowerPage,
  afterFollow,
  mt = '16px',
  myId,
}: {
  mt?: string
  isFollowerPage: boolean
  defaultVal: boolean
  afterFollow: (id: number | string, followYn: boolean) => void
  item: FollowType | undefined
  myId: number | null
}) {
  if (!item) {
    return <PendingFollowItem mt={mt} />
  }
  return (
    <>
      <Wrap $mt={mt}>
        <FollowImg myId={myId} item={item} />
        <FollowBtn
          myId={myId}
          userId={item.id}
          isFollowerPage={isFollowerPage}
          defaultVal={defaultVal}
          afterFollow={afterFollow}
        />
      </Wrap>
    </>
  )
}

const FollowImg = function FollowImg({ item, myId }: { item: FollowType; myId: number | null }) {
  const location = useLocation()
  const navigate = useNavigate()
  const { setPageStatus, pageStatus, pageValue, setPageValue } = useProfilePageStatus()
  return (
    <>
      <ProfileContainer
        onClick={() => {
          setPageStatus('main')

          if (myId && myId === item.id) {
            navigate('/profile/myprofile', {
              state: {
                direction: 'na',
              },
            })
          } else {
            navigate(`/profile/${item.id}`, {
              state: {
                direction: 'na',
              },
            })
          }
        }}
      >
        <ProfileWrap>
          <ProfileImg profileSrc={item.imageUrl || sampleImg} />
          <ProfileIntroComp h2ClassNm="follow" userNm={item.nickname} regFormat="어" />
        </ProfileWrap>
      </ProfileContainer>
    </>
  )
}

const FollowBtn = function FollowBtn({
  defaultVal,
  isFollowerPage,
  afterFollow,
  userId,
  myId,
}: {
  isFollowerPage: boolean
  defaultVal: boolean
  afterFollow: (id: number | string, followYn: boolean) => void
  userId: number
  myId: number | null
}) {
  const [clicked, setClicked] = useState(defaultVal)
  const { isPending, createForm, successRequesting, deleting } = useFollow()

  useEffect(() => {
    setClicked(defaultVal)
  }, [defaultVal])
  useEffect(() => {
    if (successRequesting) {
      setClicked(prev => !prev)
      afterFollow(userId, !clicked)
    } else if (successRequesting === false) {
      setClicked(prev => prev)
    }
  }, [successRequesting])

  return (
    <>
      {clicked ? (
        <Button
          onClick={() => {
            if (myId && myId === userId) {
              toast.error('자신의 아이디는 팔로우가 불가능 합니다.')
            } else {
              createForm(!clicked, userId)
            }
          }}
          $bc="#F4F6FA"
          $color={isFollowerPage ? '#A2A3A7' : '##242424'}
        >
          {isFollowerPage ? '팔로우' : '팔로우 취소'}
        </Button>
      ) : (
        <Button
          onClick={() => {
            createForm(!clicked, userId)
          }}
          $bc="#00C398"
          $color="#FFFFFF"
        >
          팔로우
        </Button>
      )}
    </>
  )
}

const PendingFollowItem = React.memo(function PendingFollowItem({ mt }: { mt: string }) {
  return (
    <>
      <Wrap $mt={mt}>
        <ProfileContainer>
          <ProfileWrap>
            <ProfileImg profileSrc={null} />
            <ProfileDivComp userNm={undefined} regFormat="" />
          </ProfileWrap>
        </ProfileContainer>
        <Skeleton
          variant="rectangular"
          width={89}
          height={33}
          sx={{
            borderRadius: '7px',
          }}
        />
      </Wrap>
    </>
  )
})
const Wrap = styled.div<{ $mt: string }>`
  display: flex;
  align-items: center;

  margin-top: ${({ $mt }) => `${$mt}`};
`
const Button = styled.div<{ $bc: string; $color: string }>`
  /* Frame 726 */

  /* Auto layout */

  width: 109px;
  height: 39px;

  /* Gray 02 */

  border-radius: 9px;

  background: ${({ $bc }) => `${$bc}`};

  color: ${({ $color }) => `${$color}`};

  /* Body 02-M */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height, or 153% */
  letter-spacing: -0.02em;
  text-align: center;
  align-content: center;
`
export default FollowItem
