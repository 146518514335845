import styled from 'styled-components'

export default function CharacterImg({ version, imgStyle, charId, backgroundType, onClick }: CharacterImgType) {
  const getCharByVersion = () => {
    if (version === 'xsmall') {
      if (backgroundType !== 'basic') {
        return (
          <>
            <CharcterWithBg
              charId={charId}
              backgroundType={backgroundType}
              bgImgStyle={{ width: '102px', left: '11px', top: '-16px' }}
              imgStyle={{
                width: imgStyle.width,
                height: imgStyle.height,
                right: '-6px',
                bottom: '-19px',
              }}
            />
          </>
        )
      } else {
        return (
          <>
            <CharacterWithoutBg imgStyle={imgStyle} charId={charId} />
          </>
        )
      }
    } else if (version === 'small') {
      if (backgroundType !== 'basic') {
        return (
          <>
            <CharcterWithBg
              charId={charId}
              backgroundType={backgroundType}
              bgImgStyle={{ width: '193px', left: '6px', top: '-30px' }}
              imgStyle={{
                width: imgStyle.width,
                height: imgStyle.height,
                right: '-4px',
                bottom: '-18px',
              }}
            />
          </>
        )
      } else {
        return (
          <>
            <CharacterWithoutBg imgStyle={imgStyle} charId={charId} />
          </>
        )
      }
    } else {
      if (backgroundType !== 'basic') {
        return (
          <>
            <CharcterWithBg
              charId={charId}
              backgroundType={backgroundType}
              bgImgStyle={{ width: '275px', left: '-53px', top: '-30px' }}
              imgStyle={{
                width: imgStyle.width,
                height: imgStyle.height,
                right: '-4px',
                bottom: '-18px',
              }}
            />
          </>
        )
      } else {
        return (
          <>
            <CharacterWithoutBg imgStyle={imgStyle} charId={charId} />
          </>
        )
      }
    }
  }

  return (
    <Div $width={version === 'small' ? '200px' : 'auto'} onClick={onClick}>
      {getCharByVersion()}
    </Div>
  )
}

function CharcterWithBg({ imgStyle, charId, backgroundType, bgImgStyle }: CharcterWithBgType) {
  return (
    <>
      <BgImg
        $width={bgImgStyle.width}
        $left={bgImgStyle.left}
        $top={bgImgStyle.top}
        src={require(`/src/assets/images/plant/bg/${backgroundType}.webP`)}
        alt="선택한 배경"
      />
      <BgCharImg
        $width={imgStyle.width}
        $right={imgStyle.right}
        $bottom={imgStyle.bottom}
        $height={imgStyle.height}
        style={imgStyle}
        src={require(`/src/assets/images/plant/character/char${charId}.webP`)}
        alt="선택한 캐릭터"
      />
    </>
  )
}

function CharacterWithoutBg({
  imgStyle,
  charId,
}: {
  imgStyle: {
    width: string
    height: string
  }
  charId: number
}) {
  return (
    <>
      <img
        style={imgStyle}
        src={require(`/src/assets/images/plant/character/char${charId}.webP`)}
        alt="선택한 캐릭터"
      />
    </>
  )
}

const BgImg = styled.img<BgImgProps>`
  position: absolute;
  width: ${props => props.$width};
  z-index: -1;
  left: ${props => props.$left};
  top: ${props => props.$top};
`

const BgCharImg = styled.img<BgCharImgProps>`
  position: inherit;

  right: -4px;
  bottom: -18px;
  width: ${props => props.$width};
  height: ${props => props.$height};
  right: ${props => props.$right};
  bottom: ${props => props.$bottom};
`

const Div = styled.div<{ $width: string }>`
  position: relative;
  width: ${props => props.$width};
  &:hover {
    cursor: pointer;
  }
`

type CharacterImgType = {
  charId: number
  backgroundType: string
  onClick: () => void
  imgStyle: {
    width: string
    height: string
  }
  version: string
}

type CharcterWithBgType = {
  charId: number
  backgroundType: string
  imgStyle: {
    width: string
    height: string
    right: string
    bottom: string
  }
  bgImgStyle: {
    width: string
    left: string
    top: string
  }
}

interface BgImgProps {
  $width: string
  $left: string
  $top: string
}

interface BgCharImgProps {
  $width: string
  $height: string
  $right: string
  $bottom: string
}
