import React, { Suspense, lazy } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Layout from './components/common/layout/Layout'
import { useRecoilState } from 'recoil'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { theme } from './utils/common/mui/them'
import { StyledEngineProvider } from '@mui/material/styles'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { checkTokenValidity } from './utils/common/scripts/common'
import { transitionSttsState } from './utils/common/state/state'
import FullLoadingSpinner from './components/common/container/FullLoadingSpinner'
import ErrorPage from './pages/error/ErrorPage'

// Lazy load components
const MainHome = lazy(() => import('./pages/main/MainHome'))
const Plan = lazy(() => import('./pages/plan/Plan'))
const DiaryCreate = lazy(() => import('./pages/diary/DiaryCreate'))
const CreateNewPlant = lazy(() => import('./components/specific/plant/create/CreateNewPlant'))
const MainUserCreate = lazy(() => import('./pages/user/create/MainUserCreate'))
const Login = lazy(() => import('./pages/login/Login'))
const DiaryDetail = lazy(() => import('./pages/diary/detail/DiaryDetail'))
const CollectionPage = lazy(() =>
  import('./utils/common/routes/AppRouter').then(module => ({ default: module.CollectionPage })),
)
const FindPage = lazy(() => import('./utils/common/routes/AppRouter').then(module => ({ default: module.FindPage })))
const PlantPage = lazy(() => import('./utils/common/routes/AppRouter').then(module => ({ default: module.PlantPage })))
const PlazaPage = lazy(() => import('./utils/common/routes/AppRouter').then(module => ({ default: module.PlazaPage })))
const ProfilePage = lazy(() =>
  import('./utils/common/routes/AppRouter').then(module => ({ default: module.ProfilePage })),
)

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: Error) => {
      checkTokenValidity(error)
    },
  }),
})

function App() {
  const [transitionStts, setTransitionStts] = useRecoilState<string>(transitionSttsState)
  const location = useLocation()
  const method = useForm()

  return (
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <FormProvider {...method}>
          <ThemeProvider theme={theme}>
            <Layout>
              <ToastContainer
                autoClose={2500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
              />

              <TransitionGroup
                className="transition-wrapper"
                childFactory={child => {
                  return React.cloneElement(child, {
                    classNames: location.state?.direction || 'navigate-push',
                  })
                }}
              >
                <CSSTransition
                  onEnter={() => {
                    setTransitionStts('ENTER')
                    console.log('onEnter')
                  }}
                  onEntered={() => {
                    setTransitionStts('ENTERED')
                    console.log('onEntered')
                  }}
                  onExit={() => {
                    setTransitionStts('EXIT')
                  }}
                  onExiting={() => {
                    setTransitionStts('EXITING')
                  }}
                  onExited={() => {
                    setTransitionStts(`${location.pathname}EXITED`)
                    console.log('onExited')
                  }}
                  key={location.key}
                  classNames={location.state?.direction || 'navigate-push'}
                  timeout={300}
                >
                  <Suspense
                    fallback={
                      <div>
                        <FullLoadingSpinner isShow={true} />
                      </div>
                    }
                  >
                    <Routes location={location}>
                      <Route path="/" element={<Login />} />
                      <Route path="/user/create" element={<MainUserCreate />} />
                      <Route path="/user/find/*" element={<FindPage />} />
                      <Route path="/test" element={<CreateNewPlant />} />
                      <Route path="/main" element={<MainHome />} />
                      <Route path="/plant/*" element={<PlantPage />} />
                      <Route path="/diary/detail" element={<DiaryDetail />} />
                      <Route path="/diary/create" element={<DiaryCreate />} />
                      <Route path="/plan/create" element={<Plan />} />
                      <Route path="/collection/*" element={<CollectionPage />} />
                      <Route path="/plaza/*" element={<PlazaPage />} />
                      <Route path="/profile/*" element={<ProfilePage />} />
                      <Route
                        path="/*"
                        element={
                          <>
                            <ErrorPage />
                          </>
                        }
                      />
                    </Routes>
                  </Suspense>
                </CSSTransition>
              </TransitionGroup>
            </Layout>
          </ThemeProvider>
        </FormProvider>
      </QueryClientProvider>
    </StyledEngineProvider>
  )
}

export default App
