/*
    유저가 올린 게시글
    유저가 좋아요 한 게시글
*/

import React, { memo } from 'react'
import { useGetProfilePosts } from '../hook/ProfileCreateCustomHook'
import { formatDttm, generateRandomKey } from 'src/utils/common/scripts/common'
import LineOne from 'src/components/common/line/LineOne'
import RelatedPostLayout from 'src/components/common/layout/RelatedPostLayout'
import MarkedItem from 'src/components/common/content/MarkedItem'
import MarkedItemInfo from 'src/components/common/content/MarkedItemInfo'
import ItemInfo from 'src/components/common/content/ItemInfo'
import styled from 'styled-components'
import { PendingPosts } from '../../collection/sub/CollectionRelatedPlazaPosts'
import EndLine from 'src/components/common/line/EndLie'
import NoDataContainer2 from 'src/components/common/container/NoDataContainer2'

const ProfilePosts = memo(function ProfilePosts({ type }: { type: string }) {
  const { list, changePage, isPending, isPendingUser, isMyProfile } = useGetProfilePosts()

  if (list === undefined) {
    return <PendingPosts />
  }

  if ((list && list.length === 0) || (!list && !isPending)) {
    return (
      <NoColl>
        <NoDataContainer2 mt="50px" title="작성 게시글이 없어요" discription="게시글을 작성해보세요" />
      </NoColl>
    )
  }

  return (
    <>
      <Wrap>
        {list &&
          list.map((item, index) => (
            <React.Fragment key={generateRandomKey()}>
              {item.imageUrls && item.imageUrls.length > 0 ? (
                <>
                  {index !== 0 && <LineOne />}
                  <RelatedPostLayout outterHeight={item.hashtagList && item.hashtagList.length > 0 ? '137px' : '102px'}>
                    <MarkedItem
                      onClick={() => {}}
                      item={{
                        id: item.articleId,
                        img_url: item.imageUrls[0],
                        name: item.title,
                      }}
                    />
                    <MarkedItemInfo
                      itemType="PROFILE"
                      item={{
                        id: item.articleId,
                        title: item.title || '',
                        loc: item.member.nickname,
                        regDttm: formatDttm(item.createdAt) || '',

                        content: item.content || '',
                        hashtagList: item.hashtagList ? item.hashtagList : null,
                      }}
                    />
                  </RelatedPostLayout>
                  {index === list.length - 1 && <LineOne />}
                </>
              ) : (
                <>
                  {index !== 0 && <LineOne />}
                  <ItemInfo
                    postType="PROFILE"
                    item={{
                      id: item.articleId,
                      hashtagList: item.hashtagList ? item.hashtagList : null,
                      title: item.title || '',
                      loc: item.member.nickname,
                      regDttm: formatDttm(item.createdAt) || '',
                      content: item.content || '',
                    }}
                  />
                  {index === list.length - 1 && <LineOne />}
                </>
              )}
            </React.Fragment>
          ))}
      </Wrap>
      {isPending && isMyProfile && <PendingPosts />}
      {isPendingUser && !isMyProfile && <PendingPosts />}
      <EndLine onShowEndPage={changePage} />
    </>
  )
})
export default ProfilePosts

const Wrap = styled.div``

const NoColl = styled.div`
  /* Rectangle 113 */

  width: 100%;
  aspect-ratio: 1.1068 / 1;

  display: flex;
  place-content: center;

  align-items: center;
`
