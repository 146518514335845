import * as React from 'react'
import { Global } from '@emotion/react'
import { styled } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Title from '../title/Title'
import { CONTENT_PADDING, CONTENT_PADDING_BOTTOM, CONTENT_PADDING_TOP } from 'src/utils/common/constants/constants'
import { motion } from 'framer-motion'

const drawerBleeding = 56

interface Props {
  window?: () => Window
  children: React.ReactNode
  open: boolean
  onClose: () => void
  height?: string
  p?: number
  pb?: number
  pt?: number
  title?: string
  closeInner?: boolean
  comp?: React.ReactNode
}

const Root = styled('div')(({ theme }) => ({
  height: 'auto',
  overflow: 'hidden',
}))

const StyledBox = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],

  position: 'absolute',
  top: -drawerBleeding,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  visibility: 'visible',
  right: 0,
  left: 0,

  transition: 'height 0.3s ease',

  borderRadius: '20px 20px 0px 0px',
}))

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}))

export default function DrawerLayout(props: Props) {
  const {
    window,
    children,
    onClose,
    open,
    title = '일지 작성',
    height,
    p = CONTENT_PADDING,
    pb = CONTENT_PADDING_BOTTOM,
    pt = CONTENT_PADDING_TOP,
    closeInner = false,
    comp,
  } = props
  const [innerOpen, setInnerOpen] = React.useState(false)
  const defaultH = height || '98%'
  const [hght, setHght] = React.useState(defaultH)
  const [direction, setDirection] = React.useState<null | string>(null)
  let touchStartX = 0
  let touchStartY = 0

  const toggleDrawer = (newOpen: boolean) => () => {
    if (newOpen === false) {
      setHght(defaultH)
    }
    setInnerOpen(newOpen)
  }

  React.useEffect(() => {
    if (open) {
      setInnerOpen(open)
    }
  }, [open])

  React.useEffect(() => {
    if (direction === 'up') {
      if (hght === defaultH) {
        setHght('100%')
      }
    }
  }, [direction])

  React.useEffect(() => {
    if (closeInner) {
      setInnerOpen(!open)
    }
  }, [closeInner])

  const container = window !== undefined ? () => window().document.body : undefined

  if (!open) {
    return null
  } else {
    return (
      <Root>
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              height: `calc(${hght} - ${drawerBleeding}px)`,
              overflow: 'visible',
              maxWidth: '390px',
              display: 'flex',
              margin: 'auto',
            },
          }}
        />

        <Box sx={{ overflow: 'auto', textAlign: 'center', pt: 1 }}>
          <SwipeableDrawer
            style={{ height: 'auto' }}
            container={container}
            anchor="bottom"
            open={innerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={true}
            ModalProps={{
              keepMounted: true,
              onTransitionExited: onClose,
            }}
          >
            <StyledBox>
              <div
                onTouchStart={e => {
                  touchStartX = e.touches[0].clientX
                  touchStartY = e.touches[0].clientY
                  setDirection(null) // Reset direction on touch start
                }}
                onTouchEnd={() => {
                  setDirection(null)
                  touchStartX = 0
                  touchStartY = 0
                }}
                onTouchMove={event => {
                  const touchMoveX = event.touches[0].clientX
                  const touchMoveY = event.touches[0].clientY

                  // Determine direction based on the difference in touch positions
                  if (Math.abs(touchMoveX - touchStartX) > Math.abs(touchMoveY - touchStartY)) {
                    setDirection(touchMoveX > touchStartX ? 'right' : 'left')
                  } else {
                    setDirection(touchMoveY > touchStartY ? 'down' : 'up')
                  }
                }}
              >
                <Title
                  comp={comp ? comp : undefined}
                  styleID="div_container div_mb"
                  title={title}
                  close={true}
                  onClick={toggleDrawer(false)}
                />
              </div>
              <Box
                sx={{
                  height: '130vh',
                  overflow: 'auto',
                  p: p,
                  pb: pb,
                  pt: pt,
                }}
              >
                {children}
              </Box>
            </StyledBox>
          </SwipeableDrawer>
        </Box>
      </Root>
    )
  }
}
