import { generateRandomKey } from 'src/utils/common/scripts/common'
import { useGetFollowingList } from './hook/followCustomHook'
import FollowList from './sub/FollowList'
import { useEffect, useState } from 'react'
import { FollowType } from 'src/utils/common/type/type'
import { useRecoilState } from 'recoil'
import { FollowingState } from 'src/utils/common/state/state'

type FollowingType = {
  changePage: () => void
  loadingList: boolean
  list: FollowType[] | undefined
  afterFollow: (id: number | string, followYn: boolean) => void
  myId: number | null
}

export default function Following({ myId, afterFollow, changePage, loadingList, list }: FollowingType) {
  return (
    <>
      <FollowList
        myId={myId}
        afterFollow={afterFollow}
        key={generateRandomKey()}
        isFollowerPage={false}
        changePage={changePage}
        loadingList={loadingList}
        list={list}
      />
    </>
  )
}
