import { FollowType } from 'src/utils/common/type/type'
import FollowItem from './FollowItem'
import { generateRandomKey } from 'src/utils/common/scripts/common'
import EndLine from 'src/components/common/line/EndLie'
import NoDataContainer2 from 'src/components/common/container/NoDataContainer2'
import React, { memo } from 'react'

// FollowItem을 memo로 감싸서 불필요한 렌더링을 방지
const MemoizedFollowItem = memo(FollowItem)

const FollowerList = function FollowerList({
  loadingList,
  list,
  changePage,
  isFollowerPage,
  afterFollow,
  myId,
}: {
  changePage: () => void
  loadingList: boolean
  isFollowerPage: boolean
  list: FollowType[] | undefined
  afterFollow: (id: number | string, followYn: boolean) => void
  myId: number | null
}) {
  if (loadingList) {
    return <PendingFollowList afterFollow={afterFollow} />
  }

  if (!list || list.length === 0) {
    return <NoFollowList isFollowerPage={isFollowerPage} />
  }

  return (
    <>
      {list.map((item, index) => (
        <MemoizedFollowItem
          myId={myId}
          mt={index === 0 ? '0px' : '16px'}
          isFollowerPage={isFollowerPage}
          key={item.id || generateRandomKey()} // 고유한 ID 사용
          afterFollow={afterFollow}
          defaultVal={item.isFollowed} // 나중에 변경해야 함
          item={item}
        />
      ))}
      <EndLine hidden={true} onShowEndPage={changePage} />
    </>
  )
}

const PendingFollowList = function PendingFollowList({
  afterFollow,
}: {
  afterFollow: (id: number | string, followYn: boolean) => void
}) {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <MemoizedFollowItem
          myId={null}
          mt={index === 0 ? '0px' : '16px'}
          isFollowerPage={true}
          key={generateRandomKey()} // 고유한 ID 대신 사용하기
          afterFollow={afterFollow}
          defaultVal={false}
          item={undefined}
        />
      ))}
    </>
  )
}

const NoFollowList = function NoFollowList({ isFollowerPage }: { isFollowerPage: boolean }) {
  const title = isFollowerPage ? '팔로워 목록이 없어요' : '팔로잉 목록이 없어요'
  const description = '다양한 사람들을 팔로우 해보세요!'

  return <NoDataContainer2 mt="50px" ac="center" title={title} discription={description} />
}

export default FollowerList
