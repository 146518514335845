import { useNavigate } from 'react-router-dom'
import { CustomButton } from 'src/components/common/button/CustomButton'
import NoDataContainer from 'src/components/common/container/NoDataContainer'
import ContainerLayout from 'src/components/common/layout/ContainerLayout'
import Content from 'src/components/common/layout/Content'
import MuiMain from 'src/components/common/mui/MuiMain'
import styled from 'styled-components'

export default function ErrorPage() {
  const navigate = useNavigate()
  return (
    <>
      <ContainerLayout>
        <MuiMain>
          <Content
            h="500px"
            component={
              <>
                <Div2>
                  <NoDataContainer title="없는 페이지 입니다!" discription="페이지가 없어요!" />
                </Div2>

                <Div>
                  <CustomButton
                    margin="0px 0px 16px 0px"
                    styleID="div_add_plant"
                    type="button"
                    onClick={() => {
                      navigate('/main', { state: { direction: 'back' } })
                    }}
                  >
                    메인으로
                  </CustomButton>
                </Div>
              </>
            }
          />
        </MuiMain>
      </ContainerLayout>
    </>
  )
}

const Div = styled.div`
  display: flex;

  justify-content: center;
`
const Div2 = styled.div`
  height: 58%;
`
