import MarkedItem from 'src/components/common/content/MarkedItem'
import ProfilePlantInfo from 'src/components/common/content/ProfilePlantInfo'
import RelatedPostLayout from 'src/components/common/layout/RelatedPostLayout'
import LineOne from 'src/components/common/line/LineOne'
import { fileExists, generateRandomKey, getDdate } from 'src/utils/common/scripts/common'
import { useRecoilState } from 'recoil'
import { myPlantQnt } from 'src/utils/common/state/state'
import { useEffect, useState, memo } from 'react'
import { myPlantType } from 'src/utils/common/type/type'
import { useLocation } from 'react-router-dom'
import { VALID_CHAR_LOC } from 'src/utils/common/constants/constants'
import styled from 'styled-components'
import WateringIcon from 'src/components/common/icon/WateringIcon'
import CharacterImg from 'src/components/common/charcter/CharacterImg'
import NoDataContainer2 from 'src/components/common/container/NoDataContainer2'

const NoDataWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export function ProfilePlants({ list }: { list: myPlantType[] | undefined }) {
  const [plantsQnt, setPlantsQnt] = useRecoilState(myPlantQnt)
  const location = useLocation()
  const backpath = location.state.backPath

  useEffect(() => {
    console.log(backpath + ' backpath')
  }, [backpath])

  useEffect(() => {
    if (list) {
      setPlantsQnt(list.length)
    }
  }, [list, setPlantsQnt])

  if (list === undefined) {
    return <PendingPlants />
  }

  if (list.length === 0) {
    return (
      <NoDataWrapper>
        <NoDataContainer2 title="식물이 없어요" discription="식물 리스트가 비었어요!" />
      </NoDataWrapper>
    )
  }

  return (
    <>
      {list.map(item => (
        <RelatedPostLayout
          mb="16px"
          border="0px 0px 4px rgba(0, 0, 0, 0.15)"
          br="13px"
          key={generateRandomKey()}
          height="102px"
        >
          <PlantImg
            location={item.plantLocation}
            myPlantId={item.myPlantId}
            charId={item.plantCharacterId}
            url={`/src/assets/images/plant/character/char${item.plantCharacterId}.webP`}
          />
          <ProfilePlantInfo
            item={{
              title: item.plantName,
              loc: getDdate(item.plantBirthday),
              price: '',
            }}
          />
          <WateringWrapper>
            <WateringIcon intervalIndays={item.wateringIntervalInDays} />
          </WateringWrapper>
        </RelatedPostLayout>
      ))}
    </>
  )
}

export function PlantImg({
  charId,
  url,
  myPlantId,
  location,
}: {
  charId: number
  url: string
  myPlantId: number
  location: string
}) {
  const isCharIdValid = VALID_CHAR_LOC.includes(location)
  const [imageUrl, setImageUrl] = useState<boolean>(isCharIdValid)

  return (
    <Char>
      <CharacterImg
        version="xsmall"
        backgroundType={imageUrl ? location : 'basic'}
        charId={charId}
        onClick={() => {}}
        imgStyle={
          location === 'basic' || !imageUrl
            ? { width: '91.22px', height: '91.74px' }
            : { width: '56.22px', height: '62.74px' }
        }
      />
    </Char>
  )
}

export function PendingPlants() {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => (
        <div key={generateRandomKey()}>
          <RelatedPostLayout height="102px">
            <MarkedItem onClick={() => {}} item={null} />
            <ProfilePlantInfo item={null} />
          </RelatedPostLayout>
          <LineOne />
        </div>
      ))}
    </>
  )
}

type ProfilePlantsMainProps = {
  loadingList: boolean
  successGetList: boolean
  isSuccess: boolean
  list: myPlantType[] | undefined
}

export const ProfilePlantsMain = memo(function ProfilePlantsMain({
  loadingList,
  successGetList,
  isSuccess,
  list,
}: ProfilePlantsMainProps) {
  const location = useLocation()
  const backpath = location.state.backPath

  useEffect(() => {
    console.log(backpath + ' backpath')
  }, [backpath])

  return isSuccess && list ? <ProfilePlants list={list} /> : <PendingPlants />
})

const WateringWrapper = styled.div`
  display: flex;
  align-self: self-start;
  margin-top: 23px;
  margin-right: 12px;
`
const Char = styled.div`
  z-index: 0;
  text-align: center;
  width: 309px;
`
