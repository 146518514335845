import { Map, MapMarker } from 'react-kakao-maps-sdk'
import { useState } from 'react'
import LoadingContainer from 'src/components/common/container/LoadingContainer'

type centerType = {
  // 지도의 중심좌표
  center: {
    lat: number
    lng: number
  } | null
  onChangePosition: (lat: number, lng: number) => void
  width?: string
  height?: string
  borderRadius?: string
  clickable?: boolean
}

export default function CustomMap({
  borderRadius = 'unset',
  height = '404px',
  width = '100%',
  center,
  onChangePosition,
  clickable = true,
}: centerType) {
  const [position, setPosition] = useState<{
    lat: number
    lng: number
  }>()

  if (!center) {
    return <LoadingContainer width={width} height={height} />
  } else {
    return (
      <>
        <Map // 지도를 표시할 Container
          id="map"
          center={center}
          style={{ width: width, height: height, borderRadius: borderRadius }}
          level={3} // 지도의 확대 레벨
          onClick={(_, mouseEvent) => {
            const latlng = mouseEvent.latLng
            if (clickable) {
              setPosition({
                lat: latlng.getLat(),
                lng: latlng.getLng(),
              })
              onChangePosition(latlng.getLat(), latlng.getLng())
            }
          }}
        >
          <MapMarker position={position ?? center} />
        </Map>
        {/* <p>
          <em>지도를 클릭해주세요!</em>
        </p>
        <div id="clickLatlng">
          {position && `클릭한 위치의 위도는 ${position.lat} 이고, 경도는 ${position.lng} 입니다`}
        </div> */}
      </>
    )
  }
}
